/* Define our custom fonts */

/* Including only the woff/woff2 files as they have very good browser support*/

@font-face {
  font-family: 'Akkurat Mono';
  font-weight: normal;
  font-style: normal;

  src: url(./fonts/akkurat-mono/Akkurat-Mono.woff2) format('woff2'),
    url(./fonts/akkurat-mono/Akkurat-Mono.woff) format('woff');
}
