@keyframes blink {
  /* Start animation with low opacity */
  0% {
    opacity: 0.2;
  }
  /* Fully visible then fade out slowly */
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.dot {
  animation-name: blink;
  animation-duration: var(--long-animation-duration);
  animation-iteration-count: infinite;
  /* Apply starting animation style before animation starts to have no flash */
  animation-fill-mode: both;
}

.dot:nth-child(2) {
  /* Start second dot's animation with a delay */
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  /* Start third dot's animation with a delay */
  animation-delay: 0.4s;
}
