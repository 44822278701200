
      @import '@anchorage/config/scss/variables.scss';
      @import '@anchorage/config/scss/mixins.scss';
      @import '@anchorage/config/scss/fonts.scss';
      
.disclosure {
  opacity: 0.8;
  white-space: pre-wrap;
}

/* Don't display the after arrow in this case */
.disclosure a:after {
  display: none;
}
