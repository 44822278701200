
      @import '@anchorage/config/scss/variables.scss';
      @import '@anchorage/config/scss/mixins.scss';
      @import '@anchorage/config/scss/fonts.scss';
      
.page {
  font-family: var(--font-family);

  /* Ensure the page always stretches the length of the viewport */
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: $color-black;
  display: grid;
  grid-template-rows: var(--header-height) 1fr var(--header-height);
  grid-template-columns: 48px 1fr 48px;
  grid-template-areas:
    'logo logo       logo'
    '.    contents   .'
    '.    .          .';
  color: var(--color-white);
  /* matches global var --desktop-breakpoint */
  @media (min-width: 1024px) {
    grid-template-columns: auto 844px auto;
  }
}

.contentsWrapper {
  grid-area: contents;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-areas:
    '.'
    'innerContents'
    'disclosure';
}

.contentsContainer {
  grid-area: innerContents;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.innerContainer {
  /* Stack the visible contents in a column */
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
  background: #1c1c1e;
  border-radius: 8px;
  padding: 48px;
  @media (min-width: 1024px) {
    width: 460px;
    width: reset;
    flex-direction: row;
    height: 407px;
    width: 860px;
    gap: 0;
    justify-content: space-between;
  }
}

/* Logo */
.logoWrapper {
  grid-area: logo;
  padding: 36px;
}

.logo {
  height: 48px;
  color: var(--color-basaltGray-60);
}

.qrSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--qr-code-size) + var(--frame-size) * 2);
  height: calc(var(--qr-code-size) + var(--frame-size) * 2);
  max-width: calc(var(--qr-code-size) + var(--frame-size) * 2);
}

/* Error */
.serverError {
  width: var(--qr-code-size);
  height: var(--qr-code-size);
  max-width: var(--qr-code-size);

  color: #e3e8f1;
  font-size: 1rem;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.errTitle {
  font-weight: 700;
  margin-bottom: 20px;
}

.qrWrapper {
  position: relative;
  box-shadow: none;
}

.title {
  line-height: 140%;
  font-weight: normal;
  font-size: 1.75rem;
  margin-bottom: 35px;
}

.instructions {
  font-size: 24px;
  line-height: 140%;
  font-weight: lighter;

  /* matches global var --desktop-breakpoint */
  @media (min-width: 1024px) {
    margin: unset;
  }
}

.instructionsList {
  list-style: none;
  padding-inline-start: 0px;
  counter-reset: counter;

  padding-bottom: 12px;
}

.instructionItem {
  counter-increment: counter;
  color: var(--color-marinaBlue-90);
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 12px;
}

.instructionItem::before {
  display: inline-block;
  content: counter(counter);
  margin-right: 10px;
  color: var(--color-marinaBlue-90);
  width: 40px;
  height: 40px;
  background-color: #5580f6;
  border-radius: 20px;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  line-height: 42px;
}

.instructionItemPorto::before {
  background-color: var(--color-marinaBlue-50);
  color: $color-marinaBlue-10;
}

/* Dev */
.hidden {
  /* NOTE: Copying will not work with display:none */
  overflow: hidden;
  position: absolute;
  opacity: 0;
}

.inputWrapper {
  /* Provide a hint that it's clickable for dev */
  cursor: pointer;
}

.inputWrapper > input {
  width: auto;
}

.loadingDataWrapper {
  position: relative;
}

.loader {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($color-marinaBlue-10-rgb, 0.95);
}

.refresh {
  position: relative;
  display: inline-flex;
}

.refresh:before {
  content: '';
  /* Fade out the existing QR code */
  background-color: #1c1c1e;

  opacity: 1;

  /* Fill the entire div */
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  /* Clickable */
  cursor: pointer;
  z-index: 1;
}

.refresh:after {
  content: 'Click to reload QR Code';
  color: var(--color-marinaBlue-90);

  background-color: var(--color-basaltGray-20);

  /* Fill the entire div */
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  /* Center the content */
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  border: solid 1px rgba($color-marinaBlue-90, 0.05);

  /* Clickable */
  cursor: pointer;

  z-index: 2;
}

/* Disclosure */
.disclosure {
  grid-area: disclosure;
  font-size: 12px;
  line-height: 140%;
  font-weight: normal;
  padding: var(--app-horizontal-margin) 0;
  white-space: pre-wrap;
  letter-spacing: 0.5px;
  line-height: 1.25;
  color: var(--color-basaltGray-60);
}

.qrCode {
  width: var(--qr-code-size);
  height: var(--qr-code-size);
  border: 14px solid white;
  border-radius: 4px;
}

.qrFrame {
  position: absolute;
  border: 2px solid var(--color-basaltGray-50);
  height: var(--frame-size);
  width: var(--frame-size);
}

.qrFrame:nth-of-type(2) {
  top: var(--frame-position);
  left: var(--frame-position);
  border-right: none;
  border-bottom: none;
  border-radius: 8px 0 0 0;
}

.qrFrame:nth-of-type(3) {
  top: var(--frame-position);
  right: var(--frame-position);
  border-bottom: none;
  border-left: none;
  border-radius: 0 8px 0 0;
}

.qrFrame:nth-of-type(4) {
  bottom: var(--frame-position);
  right: var(--frame-position);
  border-top: none;
  border-left: none;
  border-radius: 0 0 8px 0;
}

.qrFrame:nth-of-type(5) {
  bottom: var(--frame-position);
  left: var(--frame-position);
  border-top: none;
  border-right: none;
  border-radius: 0 0 0 8px;
}
