
      @import '@anchorage/config/scss/variables.scss';
      @import '@anchorage/config/scss/mixins.scss';
      @import '@anchorage/config/scss/fonts.scss';
      
.loading {
  width: var(--qr-code-size);
  height: var(--qr-code-size);
}

.qrCode {
  width: var(--qr-code-size);
  height: var(--qr-code-size);
  border: 14px solid white;
  border-radius: 4px;
}
